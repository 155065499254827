<script setup lang="ts">
import { computed, ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import store from "@/store";
import moment from "moment";

import {
  currencyBRtoFloat,
  currencyFloatToBR,
  maskCurrencyBR,
  maskDateBR,
  maskDateUS,
} from "@/common/utils/mask";
import { toast } from "@/common/utils/toast";

import { PanelTheme } from "@/common/themes";

import {
  baseConfirm,
  baseModal,
  baseTable,
  baseInput,
  baseSelect,
  tabMenu,
} from "@/common/components";

import { stockForm, SearchStock } from "@/components";

import {
  stockList,
  stockAdd,
  stockPut,
  stockDel,
  stockMovementList,
  stockMovementAdd,
  stockMovementDel,
  stockReport,
} from "@/common/api/rest/stock";

import type { StockType, StockPayloadType } from "@/common/types/stock";

const { t, tm } = useI18n();

const route = useRoute();

const accountId = computed(() => route.params.id as string);

const tabList = computed(() => [
  {
    key: "stock",
    label: t("common.labels.stock").toUpperCase(),
  },
  {
    key: "movement",
    label: t("common.labels.movement").toUpperCase(),
  },
  {
    key: "report",
    label: t("common.labels.report").toUpperCase(),
  },
]);

const statusEnum = computed(
  () => tm("stock.enum.status") as Record<string, string>
);

const unitEnum = computed(
  () => tm("stock.enum.units_name") as Record<string, string>
);

const typeOptions = computed(() => ({
  ENTRANCE: t("common.labels.entrance"),
  OUTPUT: t("common.labels.output"),
}));

const tableHeader = computed(() => [
  { key: "code", label: t("common.labels.code") },
  { key: "name", label: t("common.labels.name") },
  { key: "status", label: t("common.labels.status") },
  { key: "unit_name", label: t("common.labels.unit") },
  { key: "amount", label: t("common.labels.amount") },
  { key: "price", label: t("common.labels.value") },
]);

const movementTableHeader = computed(() => [
  { key: "code", label: t("common.labels.code") },
  { key: "type", label: t("common.labels.type") },
  { key: "name", label: t("common.labels.name") },
  { key: "unit_name", label: t("common.labels.unit") },
  { key: "amount", label: t("common.labels.amount") },
  { key: "price", label: t("common.labels.value") },
  { key: "total", label: t("common.labels.total") },
]);

const reportTypesOptions = computed(() => [
  {
    option: t("finance.enum.report_types.all"),
    value: "all",
  },
  {
    option: t("finance.enum.report_types.entrance"),
    value: "entrance",
  },
  {
    option: t("finance.enum.report_types.output"),
    value: "output",
  },
]);

const tableData = ref<StockType[]>([]);
const payload = ref<StockPayloadType | null>(null);
const selected = ref<StockType | null>(null);

const movementData = ref<StockType[]>([]);
const movementPayload = ref<{
  stock: StockType;
  amount: string;
  type: string;
} | null>(null);

const reportData = ref<any>(null);
const reportStartDate = ref("");
const reportEndDate = ref("");
const reportType = ref("all");

const tabActive = ref("stock");

const limit = ref<number>(10);
const page = ref<number>(0);
const pages = ref<number>(0);
const search = ref<string>("");
const searched = ref<number>(0);
const total = ref<number>(0);

const isEdit = ref(false);
const showModalAdd = ref(false);
const showConfirmDel = ref(false);
const payloadValid = ref(false);
const movementValid = ref(false);
const showMovementModalAdd = ref(false);
const showMovementModalDel = ref(false);

function fetchData() {
  stockList(accountId.value, {
    search: search.value,
    page: page.value,
    limit: limit.value,
  })
    .then((response) => {
      const { success, data } = response;

      if (success) {
        tableData.value = data.list;

        page.value = data.infos.page;
        pages.value = data.infos.pages;
        total.value = data.infos.total;
        searched.value = data.infos.count;
      } else {
        toast({ message: data.message, type: "error" });
      }
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    });
}

function fetchMovementData() {
  stockMovementList(accountId.value, {
    search: search.value,
    page: page.value,
    limit: limit.value,
  })
    .then((response) => {
      const { success, data } = response;

      if (success) {
        movementData.value = data.list;

        page.value = data.infos.page;
        pages.value = data.infos.pages;
        total.value = data.infos.total;
        searched.value = data.infos.count;
      } else {
        toast({ message: data.message, type: "error" });
      }
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    });
}

function fetchReportData() {
  stockReport(
    accountId.value,
    reportType.value,
    maskDateUS(reportStartDate.value),
    maskDateUS(reportEndDate.value)
  )
    .then((response) => {
      const { success, data } = response;

      if (success) {
        reportData.value = data;
      } else {
        toast({ message: data.message, type: "error" });
      }
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    });
}

function searchItem(input: string) {
  search.value = input;
  fetchData();
}

function changePage(value: number) {
  page.value = value;
  fetchData();
}

function selectTab(tab: string) {
  if (tabActive.value === tab) return;

  tabActive.value = tab;

  if (tab === "stock") {
    fetchData();
  }

  if (tab === "movement") {
    fetchMovementData();
  }

  if (tab === "report") {
    reportStartDate.value = moment().subtract(30, "days").format("DD-MM-YYYY");
    reportEndDate.value = moment().format("DD-MM-YYYY");
    fetchReportData();
  }
}

// STOCK

function add() {
  showModalAdd.value = true;
}

function addConfirm() {
  if (!payload.value || !payloadValid.value) return;

  showModalAdd.value = false;
  store.commit("SET_LOADING", true);

  stockAdd(accountId.value, payload.value)
    .then((response) => {
      const { success, data } = response;
      fetchData();
      submitCancel();
      toast({ message: data.message, type: success ? "success" : "error" });
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    })
    .finally(() => {
      store.commit("SET_LOADING", false);
    });
}

function edit(data: StockType) {
  isEdit.value = true;
  showModalAdd.value = true;
  selected.value = { ...data };
}

function editConfirm() {
  if (!payload.value || !payloadValid.value || !selected.value) return;

  showModalAdd.value = false;
  store.commit("SET_LOADING", true);

  stockPut(accountId.value, selected.value.stock_id, payload.value)
    .then((response) => {
      const { success, data } = response;
      fetchData();
      submitCancel();
      toast({ message: data.message, type: success ? "success" : "error" });
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    })
    .finally(() => {
      store.commit("SET_LOADING", false);
    });
}

function submitCancel() {
  payload.value = null;
  selected.value = null;
  isEdit.value = false;
  showModalAdd.value = false;
}

function submitPayload() {
  if (isEdit.value) {
    editConfirm();
    return;
  }

  addConfirm();
}

function remove(data: StockType) {
  selected.value = data;
  showConfirmDel.value = true;
}

function removeCancel() {
  selected.value = null;
  showConfirmDel.value = false;
}

function removeConfirm() {
  if (!selected.value) return;

  showConfirmDel.value = false;
  store.commit("SET_LOADING", true);

  stockDel(accountId.value, selected.value.stock_id)
    .then((response) => {
      const { success, data } = response;
      fetchData();
      removeCancel();
      toast({ message: data.message, type: success ? "success" : "error" });
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    })
    .finally(() => {
      store.commit("SET_LOADING", false);
    });
}

// MOVEMENT
function addMovement() {
  showMovementModalAdd.value = true;
}

function addMovementCancel() {
  showMovementModalAdd.value = false;
  movementPayload.value = null;
}

function addMovementConfirm() {
  if (!movementPayload.value || !movementValid.value) return;

  stockMovementAdd(accountId.value, {
    is_culture: false,
    production_id: null,
    stock_id: movementPayload.value.stock.stock_id,
    amount: movementPayload.value.amount,
    type: movementPayload.value.type,
  })
    .then((response) => {
      const { success, data } = response;
      addMovementCancel();
      fetchData();
      fetchMovementData();
      toast({ message: data.message, type: success ? "success" : "error" });
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    })
    .finally(() => {
      store.commit("SET_LOADING", false);
    });
}

function removeMovement(data: StockType) {
  selected.value = data;
  showMovementModalDel.value = true;
}

function removeMovementCancel() {
  selected.value = null;
  showMovementModalDel.value = false;
}

function removeMovementConfirm() {
  if (!selected.value) return;

  store.commit("SET_LOADING", true);

  stockMovementDel(accountId.value, selected.value.movement_id)
    .then((response) => {
      const { success, data } = response;

      if (success) {
        removeMovementCancel();
        fetchMovementData();
      } else {
        toast({ message: data.message, type: "error" });
      }
    })
    .catch(() => {
      toast({ message: t("error.default"), type: "error" });
    })
    .finally(() => {
      store.commit("SET_LOADING", false);
    });
}

function searchMovement(data: string) {
  search.value = data;
  fetchMovementData();
}

function changePageMovement(value: number) {
  page.value = value;
  fetchMovementData();
}

function formatCurrency(item: any) {
  const amount =
    item.infos.initial_amount +
    item.infos.entrance.amount -
    item.infos.output.amount;

  const format = currencyFloatToBR(String(amount));

  const parsed = parseFloat(format);

  if (parsed < 0) return `- R$ ${format.replace("-", "")}`;

  return `R$ ${format}`;
}

function changeReportType() {
  fetchReportData();
}

function changeReportDate() {
  fetchReportData();
}

onMounted(() => {
  fetchData();
});
</script>

<template>
  <panel-theme>
    <tab-menu
      :tabs="tabList"
      :active="tabActive"
      :tooltip="false"
      width="fit-content"
      padding-inline="1.5rem"
      @select:tab="selectTab"
    >
      <template #content>
        <template v-if="tabActive === 'stock'">
          <baseTable
            :header="tableHeader"
            :data="tableData"
            :limit="limit"
            :page="page"
            :pages="pages"
            :total="total"
            :searched="searched"
            :options="true"
            :edit="true"
            :del="true"
            @change:page="changePage"
            @searching="searchItem"
            @btn:add="add()"
            @btn:edit="edit"
            @btn:del="remove"
          >
            <template #status="{ data }">
              {{ statusEnum[data.status] }}
            </template>

            <template #title="{ data }">
              <div class="w-max-content">
                {{ data.title }}
              </div>
            </template>

            <template #unit_name="{ data }">
              {{ unitEnum[data.unit_name] }}
            </template>

            <template #amount="{ data }">
              {{ Number.parseFloat(data.amount || 0).toFixed(2) }}
            </template>

            <template #price="{ data }">
              R$ {{ currencyFloatToBR(data.price) }}
            </template>
          </baseTable>
        </template>

        <template v-if="tabActive === 'movement'">
          <baseTable
            :header="movementTableHeader"
            :data="movementData"
            :limit="limit"
            :page="page"
            :pages="pages"
            :total="total"
            :searched="searched"
            :options="true"
            :add="true"
            :del="true"
            @change:page="changePageMovement($event)"
            @searching="searchMovement($event)"
            @btn:add="addMovement()"
            @btn:del="removeMovement($event)"
          >
            <template #code="{ data }">
              <div class="w-max-content">
                {{ data.stock.code }}
              </div>
            </template>

            <template #name="{ data }">
              <div class="w-max-content">
                {{ data.stock.name }}
              </div>
            </template>

            <template #type="{ data }">
              {{ typeOptions[data.type] }}
            </template>

            <template #unit_name="{ data }">
              {{ unitEnum[data.stock.unit_name] }}
            </template>

            <template #amount="{ data }">
              {{ Number.parseFloat(data.amount || 0).toFixed(2) }}
            </template>

            <template #price="{ data }">
              R$ {{ currencyFloatToBR(data.stock.price) }}
            </template>

            <template #total="{ data }">
              R$ {{ currencyFloatToBR(data.stock.price * data.amount) }}
            </template>
          </baseTable>
        </template>

        <template v-if="tabActive === 'report'">
          <div id="tabReport" class="tab-report">
            <div class="header">
              <div class="flex-1">
                <base-select
                  v-model="reportType"
                  :value="reportType"
                  :options="reportTypesOptions"
                  :is-empty="false"
                  @change="changeReportType()"
                >
                  <template #label> {{ t("finance.labels.type") }}</template>
                </base-select>
              </div>

              <div class="flex-1">
                <base-input
                  v-model="reportStartDate"
                  :value="reportStartDate"
                  :mask="maskDateBR"
                  placeholder="--/--/----"
                  @blur="changeReportDate()"
                >
                  <template #label>
                    {{ t("finance.labels.start_date") }} *
                  </template>
                </base-input>
              </div>

              <div class="flex-1">
                <base-input
                  v-model="reportEndDate"
                  :value="reportEndDate"
                  :mask="maskDateBR"
                  placeholder="--/--/----"
                  @blur="changeReportDate()"
                >
                  <template #label>
                    {{ t("finance.labels.end_date") }} *
                  </template>
                </base-input>
              </div>
            </div>

            <div class="content">
              <div class="flex flex-col">
                <p>GIRO DE ESTOQUE</p>
                <table>
                  <tr>
                    <td class="uppercase">
                      {{ t("finance.labels.month") }}
                    </td>
                    <td class="uppercase">
                      {{ t("finance.labels.init") }}
                    </td>
                    <td class="uppercase">
                      {{ t("finance.labels.entrances") }}
                    </td>
                    <td class="uppercase">
                      {{ t("finance.labels.outputs") }}
                    </td>
                    <td class="uppercase">
                      {{ t("finance.labels.final") }}
                    </td>
                  </tr>
                  <template
                    v-for="(item, index) of reportData?.list"
                    :key="index"
                  >
                    <tr>
                      <td>
                        {{ item.infos.month }}
                      </td>
                      <td>
                        {{ item.infos.initial_total || 0 }}
                      </td>
                      <td>
                        {{ item.infos.entrance.total || 0 }}
                      </td>
                      <td>
                        {{ item.infos.output.total || 0 }}
                      </td>
                      <td>
                        {{ item.infos.initial_total + item.infos.entrance.total - item.infos.output.total }}
                      </td>
                    </tr>
                  </template>
                </table>
              </div>

              <div class="flex flex-col">
                <p>GIRO DE ESTOQUE EM R$</p>
                <table>
                  <tr>
                    <td class="uppercase">
                      {{ t("finance.labels.month") }}
                    </td>
                    <td class="uppercase">
                      {{ t("finance.labels.init") }}
                    </td>
                    <td class="uppercase">
                      {{ t("finance.labels.entrances") }}
                    </td>
                    <td class="uppercase">
                      {{ t("finance.labels.outputs") }}
                    </td>
                    <td class="uppercase">
                      {{ t("finance.labels.final") }}
                    </td>
                  </tr>
                  <template
                    v-for="(item, index) of reportData?.list"
                    :key="index"
                  >
                    <tr>
                      <td>
                        {{ item.infos.month }}
                      </td>
                      <td>
                        <span>R$ </span>
                        <span>
                          {{
                            currencyFloatToBR(item.infos.initial_amount || "0.00")
                          }}
                        </span>
                      </td>
                      <td>
                        <span>R$</span>
                        <span>
                          {{
                            currencyFloatToBR(
                              item.infos.entrance.amount || "0.00"
                            )
                          }}
                        </span>
                      </td>
                      <td>
                        <span>R$</span>
                        <span>
                          {{
                            currencyFloatToBR(item.infos.output.amount || "0.00")
                          }}
                        </span>
                      </td>
                      <td>
                        <span>
                          {{
                            formatCurrency(item)
                          }}
                        </span>
                      </td>
                    </tr>
                  </template>
                </table>
              </div>
            </div>
          </div>
        </template>
      </template>
    </tab-menu>

    <base-modal
      :show="showModalAdd"
      :title="t('stock.labels.stock_add').toUpperCase()"
      :show-button-confirm="true"
      :button-confirm="t('common.labels.save')"
      :button-close="t('common.labels.cancel')"
      @close="submitCancel()"
      @confirm="submitPayload()"
    >
      <template #content>
        <stock-form
          :data="selected"
          @update-data="payload = $event"
          @validate="payloadValid = $event"
        />
      </template>
    </base-modal>

    <base-modal
      :show="showMovementModalAdd"
      :show-button-confirm="true"
      :title="t('production.modals.titles.add_consumption').toUpperCase()"
      :button-confirm="t('common.labels.save')"
      :button-close="t('common.labels.cancel')"
      @close="addMovementCancel()"
      @confirm="addMovementConfirm()"
    >
      <template #content>
        <search-stock
          :account-id="accountId"
          :is-movement="true"
          @update-data="movementPayload = $event"
          @is:valid="movementValid = $event"
        />
      </template>
    </base-modal>

    <base-confirm
      :show="showConfirmDel"
      :title="t('stock.modals.titles.remove_stock').toUpperCase()"
      :message="t('stock.modals.messages.remove_stock')"
      @cancel="removeCancel()"
      @confirm="removeConfirm()"
    />

    <base-confirm
      :show="showMovementModalDel"
      :title="t('stock.modals.titles.remove_movement').toUpperCase()"
      :message="t('stock.modals.messages.remove_movement')"
      @cancel="removeMovementCancel()"
      @confirm="removeMovementConfirm()"
    />
  </panel-theme>
</template>

<style lang="scss" scoped>
.tab-report {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .header {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    table {
      width: 100%;
      border-collapse: collapse;

      th {
        padding: 0;
        border: 1px solid #ccc;

        td {
          border: none;
          border-right: 1px solid #ccc;
        }
      }

      td {
        padding: 0.5rem;
        border: 1px solid #ccc;
      }
    }
  }
}
</style>
